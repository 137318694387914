<template>
  <v-card tile :elevation="0" class="crud-box list-shipping_prices">
    <h1 class="mx-4">Manage ShippingPrices</h1>
    <v-layout wrap class="px-4 pt-4">
      <div style="overflow: visible">
        <v-btn color="success" class="mr-2" @click="onClickNew">
          <v-icon>mdi-plus</v-icon>
          New
        </v-btn>
        <v-btn color="primary" outlined class="mr-2" @click="onClickImport">
          <v-icon>mdi-database-import</v-icon>
          Import
        </v-btn>
        <v-btn color="primary" outlined class="mr-2" @click="onClickExport">
          <v-icon>mdi-database-export</v-icon>
          Export
        </v-btn>
        <v-btn color="error" v-if="pickedItems.length > 0" class="mr-2" @click="onClickDeleteAll">
          <v-icon>mdi-delete</v-icon>
          Delete Items
        </v-btn>
      </div>
      <!---->
      <v-spacer></v-spacer>
      <v-layout wrap class="search-fields-page">
        <v-autocomplete dense outlined @keyup.enter="doSearch" class="mr-2" label="Search CustomerRankId"
                      :items="customer_ranks" item-text="name" item-value="id" v-model="searchFields.customer_rank_id.value"/>
        <v-autocomplete dense outlined @keyup.enter="doSearch" class="mr-2" label="Search ServiceId"
                      :items="services" item-text="name" item-value="id" v-model="searchFields.service_id.value"/>
        <v-autocomplete style="width: 175px" dense outlined @keyup.enter="doSearch" class="mr-2" label="Search Zone"
                      :items="countries" item-text="name" item-value="country_code" v-model="searchFields.zone.value"/>
      </v-layout>
      <v-btn color="primary" @click="doSearch">
        <v-icon>mdi-magnify</v-icon>
        Search
      </v-btn>
    </v-layout>

    <!--card content-->
    <v-card tile :elevation="1" :disabled="tblLoading" outlined class="block-tbl mx-4">
      <v-data-table :headers="tblHeader" :items="listItems" mobile-breakpoint="0" :server-items-length="tblPagination.total" :loading="tblLoading"
                    :footer-props="tblConfig.footerProps" @dblclick:row="onClickRow" :options.sync="tblOptions"
                    :loading-text="tblConfig.text.loading" v-model="pickedItems" :show-select="tblConfig.showSelect">
        <template v-slot:no-data>
          {{ tblConfig.text.noItem }}
        </template>
      <template v-slot:item.no="{item}">
          {{ getIndexOfItem(item) }}
        </template>
        <template v-slot:item.customer_rank_id="{item}">
          {{ getCustomerRankName(item.customer_rank_id)}}
        </template>
        <template v-slot:item.service_id="{item}">
          {{ getServiceName(item.service_id)}}
        </template>
        <template v-slot:item.zone="{item}">
          {{ getCountriesName(item.zone)}}
        </template>

        <template v-slot:item.actions="props">
          <v-icon small @click="onClickDetail(props)" class="mr-2" color="primary">mdi-view-list</v-icon>
          <v-icon small @click="onClickEdit(props)" class="mr-2" color="success">mdi-pencil</v-icon>
          <v-icon small @click="onClickDelete(props)" color="error">mdi-delete</v-icon>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="dialog.create" :width="800">
      <CreateItem :onCreated="onCreated" :onCancel="()=>{dialog.create=false}"></CreateItem>
    </v-dialog>
    <v-dialog v-model="dialog.detail" :width="'60%'">
      <DetailItem :selectedItem="selectedItem" :onClose="()=>{dialog.detail=false}"></DetailItem>
    </v-dialog>
    <v-dialog v-model="dialog.update" :width="800">
      <UpdateItem :onUpdated="onUpdated" :selectedItem="selectedItem" :onCancel="()=>{dialog.update=false}"></UpdateItem>
    </v-dialog>
    <v-dialog v-model="dialog.delete" :width="400">
      <DeleteItem :idsToDelete="idsToDelete" :onDeleted="onDeleted" :onCancel="()=>{dialog.delete=false}"></DeleteItem>
    </v-dialog>
    <v-dialog v-model="dialog.import" :width="400">
      <ImportItem :onImported="onImported" :onCancel="()=>{dialog.import=false}"></ImportItem>
    </v-dialog>
  </v-card>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import CreateItem from "./Create";
import DetailItem from "./Detail";
import UpdateItem from "./Update";
import DeleteItem from "./Delete";
import ImportItem from "./Import";
import indexMixin from './indexMixin';

export default {
  name: 'ManageShippingPrices',
  components: {CreateItem, DetailItem, UpdateItem, DeleteItem, ImportItem},
  mixins: [indexMixin],
  data() {
    return {
      searchFields: {
        customer_rank_id: {
          value: '',
          type: 'like',
        },
        service_id: {
          value: '',
          type: 'like',
        },
        zone: {
          value: '',
          type: 'like',
        },
},
      searchWith: '',
      tblHeader: [
        {
          text: 'No',
          value: 'no',
          sortable: false,
          groupable: false,
          width: '55px',
          align: 'start',
        },

        {
          text: 'CustomerRankId',
          value: 'customer_rank_id',
        },
        {
          text: 'ServiceId',
          value: 'service_id',
        },
        {
          text: 'MinWeight',
          value: 'min_weight',
        },
        {
          text: 'Price',
          value: 'price',
        },
        {
          text: 'Currency',
          value: 'currency',
        },
        {
          text: 'Zone',
          value: 'zone',
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          groupable: false,
          width: '111px',
          align: 'center',
        }
      ],
    }
  },
  computed: {
    ...mapGetters({
      customer_ranks: 'customer_rank/all',
      services: 'service/all',
      countries: 'country/all',
      items: 'shipping_price/all',
      tblPagination: 'shipping_price/pagination'
    })
  },
  methods: {
    ...mapActions({
      getListItems: 'shipping_price/get',
      exportListItems: 'shipping_price/export',
    }),
    getServiceName(service_id){
      if(this.services.find( ({ id }) => id === service_id )){
        return this.services.find( ({ id }) => id === service_id ).name
      }else return ''
    },
    getCustomerRankName(customer_rank_id){
      if(this.customer_ranks.find( ({ id }) => id === customer_rank_id )){
        return this.customer_ranks.find( ({ id }) => id === customer_rank_id ).name
      }else return ''
    },
    getCountriesName(zone){
      if(this.countries.find( ({ id }) => id === parseInt(zone) )){
        return this.countries.find( ({ id }) => id === parseInt(zone) ).name
      }else return ''
    }
  }
}
</script>
